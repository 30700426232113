<template>
  <div class="make_over_page">
    <div class="page_top">
      <div class="title">转让园长</div>
      <div></div>
    </div>
    <el-form
      ref="makeOverFrom"
      :rules="rules"
      style="padding:0.2rem"
      :model="form"
      label-width="2rem"
    >
      <el-form-item class="form_item_block" prop="username" label="新联系人姓名">
        <el-input v-model="form.username"></el-input>
        <input type="text" style="display:block;width:0px;height:0px;opacity:0;" />
      </el-form-item>
      <el-form-item class="form_item_block" prop="password" label="新密码">
        <input type="password" style="display:block;width:0px;height:0px;opacity:0;" />
        <el-input type="password" v-model="form.password"></el-input>
      </el-form-item>
      <el-form-item
        class="form_item_block"
        prop="newPassword"
        label="再次确认密码"
      >
        <el-input type="password" v-model="form.newPassword"></el-input>
      </el-form-item>
      <el-form-item class="form_item_block" prop="mobile" label="新手机号">
        <el-input
          v-model="form.mobile"
          onkeyup="this.value = this.value.replace(/[^\d.]/g,'')"
        ></el-input>
      </el-form-item>
      <el-form-item
        class="form_item_block code_block"
        prop="code"
        label="验证码"
      >
        <el-input
          style="width:88%"
          v-model="form.code"
          maxlength="4"
          onkeyup="this.value = this.value.replace(/[^\d.]/g,'')"
        ></el-input>
        <el-button class="code" @click="getCode">{{
          PhoneCodeTime ? PhoneCodeTime + 's' : '获取'
        }}</el-button>
      </el-form-item>
      <el-form-item class="form_item_block" prop="email" label="新邮箱">
        <el-input v-model="form.email"></el-input>
      </el-form-item>
      <el-form-item class="form_item_block btns" label="">
        <el-button type="primary" class="submit" @click="submit"
          >提交</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'makeOver',
  components: {},
  data() {
    var validateName = (rule, value, callback) =>{
      if(!value){
        callback(new Error('请输入新联系人姓名'))
      }else{
        if (!this.common.textNumberCheck(value,25)) {
          callback(new Error("请输入正确格式新联系人姓名"));
        }else{
          callback();
        }
      }
    }
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if(!this.common.validateLength(value,16)){
          callback(new Error("请输入正确格式密码"));
        }else{
          if (this.form.newPassword !== '') {
            this.$refs.makeOverFrom.validateField('newPassword')
          }
          callback()
        }
        
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    var validateMobile = (rule, value, callback) => {
      if(!value){
        callback(new Error('请输入新手机号'))
      }else{
        if (!this.common.isMobile(value)) {
          callback(new Error('请输入正确格式新手机号'))
        } else {
          callback()
        }
      }
    }
    var validateEmail = (rule, value, callback)=>{
      if(!value){
        callback(new Error("请输入新邮箱"));
      }else{
        if(!this.common.validateLength(value,25)){
          callback(new Error("请输入正确格式新邮箱"));
        }else{
          if (this.common.isEmail(value)) {
            callback();
          } else {
            callback(new Error("请输入正确格式新邮箱"));
          }
        }
      }
    }
    
    return {
      form: {
        username: '',
        password: '',
        newPassword: '',
        mobile: '',
        code: '',
        email: '',
      },
      PhoneCodeTime: 0,
      rules: {
        username: [
          { required: true, validator: validateName, trigger: 'blur' },
        ],
        password: [
          { required: true, validator: validatePass, trigger: 'blur' },
        ],
        newPassword: [
          { required: true, validator: validatePass2, trigger: 'blur' },
        ],
        mobile: [
          { required: true, validator: validateMobile, trigger: 'blur' },
        ],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        email: [
          { required: true, validator: validateEmail, trigger: 'blur' },
          {
            type: 'email',
            message: '请输入正确的邮箱地址',
            trigger: ['blur'],
          },
        ],
      },
    }
  },
  created() {},
  mounted() {},
  methods: {
    getCode() {
      if (!this.PhoneCodeTime) {
        if (!this.form.mobile) {
          this.$notice({
            message: '请输入联系人手机号'
          }).isShow()
          return
        }
        if (!this.common.isMobile(this.form.mobile)) {
          this.$notice({
            message: '请输入正确格式的手机号码'
          }).isShow()
          return
        }

        this.PhoneCodeTime = 180
        let timer = setInterval(() => {
          this.PhoneCodeTime = this.PhoneCodeTime - 1
          if (this.PhoneCodeTime == 0) {
            this.PhoneCodeTime = ''
            clearInterval(timer)
          }
        }, 1000)
        this.api.user
          .changePassSend({
            phone: this.form.mobile,
          })
          .then((res) => {
            if (res.flag) {
               this.$notice({
                type: 'success',
                message: '发送成功'
              }).isShow()
            } else {
                              this.$notice({
                  message: res.message
                }).isShow()
            }
          })
      } else {
        this.$notice({
          message: '请在倒计时结束后再次发送验证码'
        }).isShow()
      }
    },
    submit() {
      this.$refs.makeOverFrom.validate((valid) => {
        if (valid) {
          let data = {
            username: this.form.username,
            password: this.form.password,
            mobile: this.form.mobile,
            code: this.form.code,
            email: this.form.email,
          }
          this.api.admin.changeDirector(data).then((res) => {
            if (res.flag) {
              this.$notice({
                type: 'success',
                message: '转让成功'
              }).isShow()
              this.$store.dispatch('out', this)
            } else {
                              this.$notice({
                  message: res.message
                }).isShow()
            }
          })
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.make_over_page {
  .page_top {
    @include flex(row, space-between, center);
    margin-bottom: 50px;
    border-bottom: 10px solid #cc1a30;

    .title {
      font-size: 34px;
      font-family: Alibaba PuHuiTi;
      font-weight: bold;
      color: #cc1a30;
      margin-bottom: 30px;
    }
  }
}
</style>
<style lang="scss">
.make_over_page {
  .el-form-item__label {
    font-size: 25px;
    line-height: 70px;
    padding-right: 20px;
  }

  .el-input__inner {
    height: 70px;
    font-size: 25px;
    border-width: 1px; /*no*/
    border-color: #857c7a;
    color: #000;
  }

  .form_item_block {
    margin-bottom: 40px !important;
  }

  .el-form-item__content {
    @include flex();
  }

  .code {
    width: 10%;
    height: 70px;
    margin-left: 2%;
    border-width: 1px; /*no*/
    font-size: 25px;
    border-color: #857c7a;
    box-sizing: border-box;
    text-align: center;
    line-height: 70px;
    padding: 0;
  }

  .btns {
    @include flex(row, flex-end, center);
  }

  .submit {
    width: 100px;
    font-size: 0.25rem;
    line-height: 0.7rem;
  }
}
</style>
